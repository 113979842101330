//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  acceptAddNextNode,
  acceptDocumentBill,
  acceptNextNode,
  carbonCopyBill,
} from '@/api/modular/fileStream/documentManage'
import { selectPerson } from '@/mixins'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
  dealResult,
  getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
export default {
  props: {
    documentNodeList: {
      type: Array,
      default() {
        return []
      },
    },
    approvalStatus: {
      type: Number,
      default: 0,
    },
    templateName: {
      type: String,
      default: '',
    },
    recipientStatus: {
      type: Number,
      default: 0,
    },
    from: {
      type: String,
      default: '',
    },
  },
  mixins: [selectPerson],
  components: {
    OpenData,
    selectPersonDialog,
  },
  computed: {
    documentNodeListCopy() {
      const documentNodeList = this.finnalyDocumentNodeList.slice()
      const cs = this.documentNodeList.find((j) => j.sort === 100)
      // documentNodeList = documentNodeList.filter(node => node.sort !== -1)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.csr_list = (cs?.nodes || []).map((j) => ({
        corpId: j.corpId,
        [j.deptId ? 'id' : 'userId']: j.deptId || j.userId,
        type: this.getDataType(j),
      }))
      return documentNodeList
    },
    finnalyDocumentNodeList() {
      const documentNodeList = this.documentNodeList.slice()
      documentNodeList.forEach((j) => {
        if (![-1].includes(j.sort)) {
          this.formatNodeMess(j)
        }
      })
      return documentNodeList
    },
    stepIndex() {
      return this.documentNodeListCopy.findIndex((node) => node.nodeFlag === 0) || 0
    },
    allList() {
      let _list = this.list.map((item) => item.val)
      return [..._list, ...this.csr_list]
    },
  },
  data() {
    return {
      modelVisible: false,
      addNodesLoading: false,
      approvalId: '',
      nodeId: '',
      csr_list: [],
      list: [
        {
          name: '節點',
          val: [],
        },
      ],
    }
  },

  methods: {
    handleCancel() {
      this.modelVisible = false
    },
    formatNodeMess(node) {
      const arr = []
      const getApprovalTime = (deptUsers) => {
        if (deptUsers.some((l) => l.approvalFlag === 0)) return null
        const times = deptUsers.map((user) => {
          const timeStr = user.approvalTime.replace(/-/g, '/')
          const time = new Date(timeStr).getTime()
          return time
        })
        return this.formatApprovalTime(Math.max(times))
      }
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          arr.push({
            deptId: dep.deptId,
            deptUsers: dep.deptUsers,
            corpId: dep.deptUsers[0].corpId,
            approvalTime: getApprovalTime(dep.deptUsers),
            status: Number(!dep.deptUsers.some((l) => l.status === 0)),
            approvalFlag: Number(!dep.deptUsers.some((l) => l.approvalFlag === 0)),
          })
        })
      }
      let array = []
      array = array.concat(
        [...node.nodes, ...arr].filter((j) => j.approvalFlag === 1),
        [...node.nodes, ...arr].filter((j) => j.approvalFlag !== 1)
      )
      node.nodes = array
    },
    getDataType(node) {
      return node.deptId ? 'departmentName' : 'userName'
    },
    addNodes({ id }, nodeId) {
      this.approvalId = id
      this.nodeId = nodeId
      this.modelVisible = true
    },
    /**
     * 接收公文
     */
    acceptBill() {
      acceptDocumentBill(this.$route.query.id)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('接收成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    carbonCopy({ id }) {
      carbonCopyBill({
        nodeId: id,
        userList: this.csr_list.map(({ userId, corpId, id, type }) => ({
          [this.getIdKey(type)]: userId || id,
          corpId,
        })),
      })
        .then((res) => {
          console.log(res)
          if (res.code === 200) {
            this.$message.success('抄送成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getIdKey(type) {
      return type === 'departmentName' ? 'departmentId' : 'userId'
    },
    handleOk() {
      if (this.list.some((j) => !j.val || j.val.length === 0)) {
        this.$message.warning('存在節點沒有選擇人員！')
        return
      }
      if (this.addNodesLoading) return
      this.addNodesLoading = true
      const documentNodeParams = this.list.map((el, index) => ({
        billId: this.$route.query.id,
        sort: index,
        name: el.name,
        approvals: el.val.map(({ corpId, userId, id, type }) => ({
          [this.getIdKey(type)]: userId || id,
          corpId: corpId,
        })),
      }))
      acceptAddNextNode({
        documentNodeParams,
        approvalId: this.approvalId,
        nodeId: this.nodeId,
      })
        .then((res) => {
          console.log(res)
          if (res.code === 200) {
            this.$message.success('添加成功')
            this.$nextTick(() => {
              this.$router.push({ path: '/fileStreamDocumentManage', replace: true })
              // this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.modelVisible = false
          this.addNodesLoading = false
        })
    },
    delDom(i) {
      this.list.splice(i, 1)
    },
    dealNext(id, nodeId) {
      acceptNextNode({
        billId: this.$route.query.id,
        nodeId: id,
        approvalId: nodeId,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('流程確認成功！')
            this.$nextTick(() => {
              this.$router.push({ path: '/fileStreamDocumentManage', replace: true })
            })
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editDom() {
      const dom = arguments[0].target.parentElement.getElementsByClassName('input-dom')[0]
      dom.removeAttribute('readOnly')
      dom.classList.remove('input-dom-edit')
    },
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return dateStr.replace(/\//g, '-')
    },
    choosePerson(list, index) {
      let val
      if (list === 'list') {
        val = [...this[list][index].val]
      } else {
        val = [...this[list]]
      }
      const {
        selectedOpenUserIds,
        selectedCorpGroupUserIds,
        selectedDepartmentIds,
        selectedCorpGroupDepartmentIds,
        isList,
      } = getUserListMsg(list, index, val)
      selectPersonAndDepart({
        selectedOpenUserIds,
        selectedCorpGroupDepartmentIds,
        selectedDepartmentIds,
        selectedCorpGroupUserIds,
      })
        .then((res) => {
          const { result, resPart } = dealResult(res)
          let isUserNode =
            this.confineNodeSelectByChoosePerson && this.confineNodeSelectByChoosePerson(result, this[list])
          if (isList) {
            isUserNode && this.$set(this[list][index], 'val', [...result, ...resPart])
          } else {
            isUserNode && this.$set(this, list, [...result, ...resPart])
          }
        })
        .catch((err) => {
          console.log(err)
        })
      // this.$refs.sp_user.add(list, index)
    },
    addDom() {
      this.list.push({ name: '節點', val: [] })
    },
    formatApprovalStatus(approvalFlag, sort, status) {
      if (sort === 99) {
        return approvalFlag ? '已接收' : '待接收'
      }
      if (sort === 100) {
        return approvalFlag ? '已通知' : '待通知'
      }
      if (approvalFlag === 0) {
        return '待審核'
      } else if (approvalFlag === 3) {
        return '同級已審'
      } else if (status === 1) {
        return '審核通過'
      } else {
        return '駁回'
      }
    },
  },
}
