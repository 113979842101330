//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line camelcase
import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import mytable from '@/views/fileStream/documentApproval/documentManage/table.vue'
import editor from '@/views/fileStream/documentManage/editor'
import upload from '@/views/fileStream/documentManage/upload'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import billTable from '@/views/fileStream/documentSend/components/billTable.vue'
import fastTable from '@/views/fileStream/documentSend/components/fastTable.vue'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
import myform from './form.vue'
export default {
    components: { mytable, myform, upload, editor, processMess, billTable, fastTable },
    data() {
        return {
            fileIds: [],
            documentNodeList: [],
            recipientStatus: 0,
            approvalStatus: 0,
            templateName: '',
            content: '',
            documentType: 0,
            nooseStatus: 0,
            from: '',
            info: {},
            billInfo: {}, // 擬稿發文數據
            type: 1,
        }
    },
    created() {
        init_wx()
        this.get_info()
    },
    watch: {
        $route() {
            this.get_info()
        },
    },
    methods: {
        get_info() {
            const { id, from, type } = this.$route.query
            this.type = type
            this.from = from || ''
            if (id) {
                documentBillDetail({ id }).then((res) => {
                    if (res.code === 200 && res.data) {
                        const { data } = res
                        if (!data) return
                        this.info = data
                        this.billInfo = {
                            ...data,
                            jjcd: data.urgencyDegree,
                            miji: data.secrecyDegree,
                            userId: data.draftId,
                            xzfn: String(data.fileType),
                            fileIds: data.documentFileList,
                        }
                        const {
                            content,
                            documentFileList,
                            documentNodeList,
                            recipientStatus,
                            approvalStatus,
                            nooseStatus,
                            documentType,
                            templateName,
                        } = data
                        this.content = content
                        this.fileIds = documentFileList
                        this.documentNodeList = documentNodeList
                        this.approvalStatus = approvalStatus
                        this.recipientStatus = recipientStatus
                        this.templateName = templateName
                        this.documentType = documentType
                        this.nooseStatus = nooseStatus
                    }
                })
            }
        },
    },
}
